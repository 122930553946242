<template>
    <router-view />
</template>

<script>
export default {
    mounted() {
        // this.$ajax.get(' http://192.168.0.245:8080/', {}).then(res => {
        //     console.log(res);
        // });
    }
};
</script>

<style lang="scss" scoped>

</style>
